const COMPONENT_NAMES = {
    // Ideally these go in the general order that the steps might appear
    SHOPPING_FOR: 'ShoppingFor',
    EV_CHARGER: 'EvCharger',
    PROPERTY_TYPE: 'PropertyType',
    BUSINESS_CONTACT: 'BusinessContact',
    OWNS_PROPERTY: 'OwnsProperty',
    OWNS_PROPERTY_COMMERCIAL: 'OwnsPropertyCommercial',
    TIME_TO_PURCHASE: 'TimeToPurchase',
    RENT_CRITERIA: 'RentCriteria',
    CSM_HANDOFF: 'CsmHandoff',
    SUSTAINABILITY: 'Sustainability',
    ELECTRICITY_BILL: 'ElectricityBill',
    SERVICE_ERROR: 'ServiceError',
    STORAGE_INTEREST: 'StorageInterest',
    STORAGE_REASON: 'StorageReason',
    ADDRESS: 'Address',
    ROOF_PIN: 'RoofPin',
    ROOF_PIN_HELP: 'RoofPinHelp',
    ROOF_AGE: 'RoofAge',
    REMOVE_TREES: 'RemoveTrees',
    ORGANIZATION_NAME: 'OrganizationName',
    NAME: 'Name',
    EMAIL: 'Email',
    PHONE: 'Phone',
    VERIFY_COMPLETE: 'VerifyComplete',
    SCHEDULE_CALL: 'ScheduleCall',
    HEAT_PUMPS_INSTALLER_SEARCH: 'HeatPumpsInstallerSearch',
};

const CHOICE_VALUES = {
    ROOFTOP: 'rooftop',
    EV: 'ev',
    HEATPUMP: 'heatpump',
    RESIDENTIAL: 'residential',
    COMMERCIAL: 'commercial',
    NONPROFIT: 'nonprofit',
    INTERESTED: 'interested',
    NOT_INTERESTED: 'not_interested',
    BACKUP: 'EBP',
    SAVINGS: 'UR',
    SELF_SUPPLY: 'SS',
    OLD_ROOF: 'more_than_20',
    NEW_ROOF: 'less_than_20',
    OLD_ROOF_REPLACE: 'more_than_20_replacing',
    YES_REMOVE: 'yes',
    NO_REMOVE: 'no',
    OWNER: 'owner',
    NON_OWNER: '',
    RENT_OTHER: 'shopping_someone_else',
    RENT_HOA: 'shopping_hoa_or_multicondo',
    RENT_SELF: 'rent_for_self',
    REPRESENT_OWNER: 'represent_owner',
    COMMERCIAL_OWNER: 'owner',
    COMMERCIAL_LEASE: 'commercial_lessee',
    WITHIN_MONTHS: 'next_few_months',
    WITHIN_YEAR: 'next_year',
    NOT_SURE: 'unsure',
    TRUE: 'true',
    FALSE: 'false',
    SCHEDULE_CALL_CLICKED: 'schedule_call_clicked',
};

export const FINAL_STEPS = [COMPONENT_NAMES.VERIFY_COMPLETE, COMPONENT_NAMES.CSM_HANDOFF];

export const CHOICE_STEP_CHANGES = {
    SHOPPING_FOR: {
        ROOFTOP: [
            { EV_CHARGER: false },
            { PROPERTY_TYPE: true },
            { OWNS_PROPERTY: true },
            { OWNS_PROPERTY_COMMERCIAL: true },
            { TIME_TO_PURCHASE: true },
            { CSM_HANDOFF: false },
            { ORGANIZATION_NAME: true },
            { PHONE: true },
            { STORAGE_INTEREST: true },
            { REMOVE_TREES: true },
            { ROOF_AGE: true },
            { ELECTRICITY_BILL: true },
            { ADDRESS: true },
            { ROOF_PIN: true },
            { NAME: true },
            { EMAIL: true },
            { PHONE: true },
            { VERIFY_COMPLETE: true },
        ],
        EV: [
            { EV_CHARGER: true },
            { PROPERTY_TYPE: false },
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: false },
            { TIME_TO_PURCHASE: false },
            { CSM_HANDOFF: false },
            { ORGANIZATION_NAME: false },
            { PHONE: false },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
            { ELECTRICITY_BILL: false },
            { ADDRESS: false },
            { ROOF_PIN: false },
            { NAME: false },
            { EMAIL: false },
            { PHONE: false },
            { VERIFY_COMPLETE: false },
        ],
        HEATPUMP: [
            { EV_CHARGER: false },
            { PROPERTY_TYPE: false },
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: false },
            { TIME_TO_PURCHASE: false },
            { CSM_HANDOFF: false },
            { ORGANIZATION_NAME: false },
            { PHONE: false },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
            { ELECTRICITY_BILL: false },
            { ADDRESS: false },
            { ROOF_PIN: false },
            { NAME: false },
            { EMAIL: false },
            { PHONE: false },
            { VERIFY_COMPLETE: false },
        ],
    },
    EV_CHARGER: {},
    PROPERTY_TYPE: {
        RESIDENTIAL: [
            { OWNS_PROPERTY: true },
            { OWNS_PROPERTY_COMMERCIAL: false },
            { TIME_TO_PURCHASE: false },
            { ORGANIZATION_NAME: false },
            { PHONE: false },
            { STORAGE_INTEREST: true },
            { REMOVE_TREES: true },
            { ROOF_AGE: true },
        ],
        COMMERCIAL: [
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: true },
            { TIME_TO_PURCHASE: true },
            { ORGANIZATION_NAME: true },
            { PHONE: true },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
        ],
        NONPROFIT: [
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: true },
            { TIME_TO_PURCHASE: true },
            { ORGANIZATION_NAME: true },
            { PHONE: true },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
        ],
    },

    OWNS_PROPERTY: {
        TRUE: [{ RENT_CRITERIA: false }],
        // Rent means a personal non-commercial lease, so the residential rent criteria step is shown
        FALSE: [{ RENT_CRITERIA: true }],
    },
    OWNS_PROPERTY_COMMERCIAL: {},
    TIME_TO_PURCHASE: {},
    RENT_CRITERIA: {
        RENT_OTHER: [
            { STORAGE_INTEREST: true },
            { REMOVE_TREES: true },
            { ROOF_AGE: true },
            { ELECTRICITY_BILL: true },
        ],
        RENT_HOA: [{ STORAGE_INTEREST: true }, { REMOVE_TREES: true }, { ROOF_AGE: true }, { ELECTRICITY_BILL: true }],
        RENT_SELF: [
            { STORAGE_INTEREST: false },
            { STORAGE_REASON: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
            { ELECTRICITY_BILL: false },
        ],
    },
    CSM_HANDOFF: {},
    ELECTRICITY_BILL: {},
    STORAGE_INTEREST: {
        INTERESTED: [{ STORAGE_REASON: true }],
        NOT_INTERESTED: [{ STORAGE_REASON: false }],
    },
    STORAGE_REASON: {},
    ADDRESS: {},
    ROOF_PIN: {},
    ROOF_AGE: {},
    REMOVE_TREES: {},
    ORGANIZATION_NAME: {},
    NAME: {},
    EMAIL: {},
};

const NUMERIC_CONSTANT_VALUES = {
    CO2_TONS_PER_KWH: 0.0007601,
    CO2_TREES_PER_KWH: 0.0176808,
    AVG_NATIONAL_ELECTRICITY_BILL: 23, // 23 cents/kwh
};

const SERVICE_LEVEL_VALUES = {
    FULL: 'full',
    LIMITED: 'limited',
    NONE: 'none',
};

const handler = {
    get(target, name) {
        if (name in target) {
            return target[name];
        }
        throw new Error(`invalid key: ${name}`);
    },
};

export const COMPONENTS = new Proxy(COMPONENT_NAMES, handler);
export const COMPONENT_COUNT = Object.keys(COMPONENT_NAMES).length;
export const CHOICES = new Proxy(CHOICE_VALUES, handler);
export const NUMERIC_CONSTANT = new Proxy(NUMERIC_CONSTANT_VALUES, handler);
export const SERVICE_LEVEL = new Proxy(SERVICE_LEVEL_VALUES, handler);
export const COMMERCIAL_PROPERTY_TYPES = [CHOICES.COMMERCIAL, CHOICES.NONPROFIT];

export const MARKET_URL = '/market/';
export const ES_SITE_MARKET_START_PATH = '/market/start/';

export const PROPERTY_OVERRIDE_COOKIE_NAME = 'mfdp_mvp_property_type_override';
const COOKIE_PATH = '/';
const MAX_AGE_IN_SECONDS = 15;
const SAME_SITE_RULE = 'lax';
export const PROPERTY_OVERRIDE_COOKIE_PARAMS = {
    path: COOKIE_PATH,
    maxAge: MAX_AGE_IN_SECONDS,
    sameSite: SAME_SITE_RULE,
};
export const ERROR_REASON = {
    EMAIL_EXISTS: 'EMAIL_EXISTS',
    SUBMISSION: 'SUBMISSION',
    COOKIE_AUTHORIZATION: 'COOKIE_AUTHORIZATION',
    NO_SERVICE: 'NO_SERVICE',
    UNKNOWN: 'UNKNOWN',
};

export const GOOGLE_MAPS_API_MAX_LOAD_TIME = 1500;

export const GOOGLE_MAPS_API_LIBRARIES = {
    CORE: 'core',
    MAPS: 'maps',
    MARKER: 'marker',
    PLACES: 'places',
};

export const HP_EA_PHONE_NUMBER = '(617) 284-4960';

// TODO: add NexStar zip codes here
export const NEXSTAR_ZIP_CODES = new Set([
    '60007',
    '60478',
    '75409',
    '23234',
    '60010',
    '60803',
    '75043',
    '23231',
    '60002',
    '60404',
    '76040',
    '22942',
    '60103',
    '60412',
    '75212',
    '22747',
    '60005',
    '60418',
    '75454',
    '22729',
    '60009',
    '60423',
    '75034',
    '22663',
    '60012',
    '60439',
    '75010',
    '22734',
    '60014',
    '60446',
    '76006',
    '22709',
    '60124',
    '60448',
    '75207',
    '22738',
    '60016',
    '60490',
    '75071',
    '22701',
    '60137',
    '60504',
    '76227',
    '22712',
    '60017',
    '60515',
    '75007',
    '22660',
    '60143',
    '60452',
    '75050',
    '22551',
    '60154',
    '60523',
    '75208',
    '22546',
    '60018',
    '60543',
    '75424',
    '22534',
    '60169',
    '60545',
    '75068',
    '22485',
    '60019',
    '60554',
    '75287',
    '22463',
    '60175',
    '60558',
    '75060',
    '22433',
    '60181',
    '60560',
    '75223',
    '22408',
    '60020',
    '60462',
    '75078',
    '22407',
    '60185',
    '60585',
    '75006',
    '22406',
    '60188',
    '60467',
    '75019',
    '22405',
    '60190',
    '60464',
    '75061',
    '22403',
    '60193',
    '60482',
    '75224',
    '22401',
    '60195',
    '60430',
    '75009',
    '22315',
    '60025',
    '60008',
    '76205',
    '22657',
    '60410',
    '60101',
    '75234',
    '22655',
    '60026',
    '60107',
    '75150',
    '22650',
    '60029',
    '60118',
    '75211',
    '22314',
    '60030',
    '60123',
    '75069',
    '22312',
    '60031',
    '60126',
    '76210',
    '22310',
    '60033',
    '60134',
    '75229',
    '22309',
    '60037',
    '60139',
    '75041',
    '22308',
    '60040',
    '60148',
    '75236',
    '22307',
    '60041',
    '60157',
    '75002',
    '22645',
    '60042',
    '60172',
    '75065',
    '22630',
    '60043',
    '60174',
    '75067',
    '22306',
    '60044',
    '60177',
    '75244',
    '22625',
    '60045',
    '60184',
    '75233',
    '22624',
    '60046',
    '60187',
    '75407',
    '22304',
    '60047',
    '60189',
    '76226',
    '22303',
    '60048',
    '60192',
    '76092',
    '22620',
    '60050',
    '60194',
    '75230',
    '22611',
    '60053',
    '60197',
    '75206',
    '22610',
    '60056',
    '60432',
    '75098',
    '22602',
    '60061',
    '60441',
    '75077',
    '22565',
    '60062',
    '60502',
    '76177',
    '22556',
    '60064',
    '60506',
    '75201',
    '22554',
    '60068',
    '60513',
    '75094',
    '22553',
    '60069',
    '60517',
    '75022',
    '22211',
    '60070',
    '60527',
    '76244',
    '22209',
    '60072',
    '60538',
    '75220',
    '22207',
    '60073',
    '60540',
    '75202',
    '22205',
    '60074',
    '60563',
    '75048',
    '22204',
    '60076',
    '60565',
    '76262',
    '22203',
    '60077',
    '60199',
    '76182',
    '22202',
    '60078',
    '60416',
    '75235',
    '22201',
    '60079',
    '60421',
    '75226',
    '22193',
    '60081',
    '60433',
    '75089',
    '22192',
    '60082',
    '60434',
    '75057',
    '22191',
    '60083',
    '60444',
    '76137',
    '22182',
    '60084',
    '60474',
    '75225',
    '22181',
    '60085',
    '60507',
    '75246',
    '22180',
    '60087',
    '60522',
    '75088',
    '22172',
    '60088',
    '60541',
    '75056',
    '22156',
    '60090',
    '60572',
    '76148',
    '22153',
    '60091',
    '60598',
    '75231',
    '22152',
    '60093',
    '60935',
    '75040',
    '22151',
    '60095',
    '75024',
    '22150',
    '60096',
    '76180',
    '22134',
    '60097',
    '75238',
    '22125',
    '60098',
    '75044',
    '22124',
    '60099',
    '75093',
    '22102',
    '60100',
    '76039',
    '22101',
    '60102',
    '75209',
    '22079',
    '60106',
    '75070',
    '22070',
    '60119',
    '75080',
    '22066',
    '60130',
    '76021',
    '22045',
    '60131',
    '75205',
    '22044',
    '60140',
    '75035',
    '22043',
    '60152',
    '75252',
    '22042',
    '60153',
    '76022',
    '22041',
    '60155',
    '75214',
    '22039',
    '60156',
    '75013',
    '22036',
    '60160',
    '75248',
    '92054',
    '60164',
    '76111',
    '85635',
    '60165',
    '75218',
    '73054',
    '60170',
    '75025',
    '63885',
    '60171',
    '75254',
    '45430',
    '60176',
    '76117',
    '42704',
    '60180',
    '75228',
    '38125',
    '60183',
    '75023',
    '30716',
    '60201',
    '75240',
    '26851',
    '60202',
    '76118',
    '26113',
    '60203',
    '75247',
    '25556',
    '60301',
    '75075',
    '25428',
    '60304',
    '75243',
    '25427',
    '60108',
    '76120',
    '25425',
    '60120',
    '75219',
    '25419',
    '60133',
    '75074',
    '25414',
    '60173',
    '75042',
    '25413',
    '60403',
    '76053',
    '25405',
    '60415',
    '75204',
    '24620',
    '60422',
    '24503',
    '60429',
    '24501',
    '60431',
    '24477',
    '60435',
    '24406',
    '60440',
    '24127',
    '60443',
    '23917',
    '60447',
    '23875',
    '60451',
    '23866',
    '60453',
    '23860',
    '60463',
    '23842',
    '60465',
    '23834',
    '60477',
    '23832',
    '60480',
    '23831',
    '60487',
    '23669',
    '60491',
    '23666',
    '60503',
    '23608',
    '60505',
    '23602',
    '60510',
    '23518',
    '60514',
    '23462',
    '60516',
    '23456',
    '60521',
    '23454',
    '60525',
    '23435',
    '60532',
    '23434',
    '60539',
    '23324',
    '60542',
    '23323',
    '60544',
    '23320',
    '60546',
    '23317',
    '60555',
    '23306',
    '60559',
    '23304',
    '60561',
    '23238',
    '60564',
    '23226',
    '60567',
    '23224',
    '60586',
    '23192',
    '60004',
    '23188',
    '60013',
    '23177',
    '60015',
    '23150',
    '60021',
    '23117',
    '60022',
    '23116',
    '60035',
    '23114',
    '60051',
    '23113',
    '60060',
    '23112',
    '60065',
    '23111',
    '60067',
    '23093',
    '60071',
    '23065',
    '60089',
    '23060',
    '60104',
    '23059',
    '60105',
    '23024',
    '60110',
    '23015',
    '60125',
    '22972',
    '60136',
    '22967',
    '60142',
    '22963',
    '60162',
    '22960',
    '60163',
    '22948',
    '60191',
    '22943',
    '60302',
    '22923',
    '60305',
    '22911',
    '60402',
    '22902',
    '60428',
    '22901',
    '60445',
    '22853',
    '60455',
    '22851',
    '60456',
    '22844',
    '60457',
    '22842',
    '60458',
    '22835',
    '60459',
    '22832',
    '60471',
    '22827',
    '60501',
    '22824',
    '60519',
    '22810',
    '60526',
    '22749',
    '60534',
    '22748',
    '60601',
    '22746',
    '60602',
    '22743',
    '60603',
    '22742',
    '60605',
    '22741',
    '60606',
    '22740',
    '60607',
    '22739',
    '60608',
    '22737',
    '60609',
    '22736',
    '60610',
    '22735',
    '60611',
    '22733',
    '60613',
    '22732',
    '60614',
    '22731',
    '60615',
    '22730',
    '60616',
    '22728',
    '60618',
    '22727',
    '60622',
    '22726',
    '60625',
    '22724',
    '60626',
    '22722',
    '60630',
    '22720',
    '60631',
    '22719',
    '60634',
    '22718',
    '60638',
    '22716',
    '60639',
    '22715',
    '60640',
    '22714',
    '60641',
    '22713',
    '60642',
    '22707',
    '60643',
    '22665',
    '60645',
    '22664',
    '60646',
    '22656',
    '60647',
    '22654',
    '60651',
    '22652',
    '60652',
    '22646',
    '60653',
    '22644',
    '60654',
    '22643',
    '60655',
    '22642',
    '60656',
    '22641',
    '60657',
    '22640',
    '60659',
    '22639',
    '60660',
    '22637',
    '60661',
    '22627',
    '60664',
    '22623',
    '60689',
    '22604',
    '60691',
    '22603',
    '60706',
    '22601',
    '60707',
    '22580',
    '60712',
    '22567',
    '60714',
    '22560',
    '60804',
    '22555',
    '60805',
    '22552',
    '22542',
    '22538',
    '22535',
    '22520',
    '22514',
    '22508',
    '22488',
    '22471',
    '22469',
    '22451',
    '22450',
    '22448',
    '22446',
    '22443',
    '22438',
    '22436',
    '22430',
    '22427',
    '22404',
    '22402',
    '22333',
    '22330',
    '22321',
    '22317',
    '22313',
    '22311',
    '22305',
    '22302',
    '22301',
    '22222',
    '22219',
    '22217',
    '22215',
    '22214',
    '22213',
    '22206',
    '22196',
    '22195',
    '22190',
    '22186',
    '22185',
    '22183',
    '22171',
    '22170',
    '22165',
    '22164',
    '22159',
    '22154',
    '22141',
    '22132',
    '22130',
    '22129',
    '22122',
    '22113',
    '22111',
    '22110',
    '22105',
    '22094',
    '22091',
    '22090',
    '22076',
    '22075',
    '22071',
    '22065',
    '22062',
    '22061',
    '22060',
    '22047',
    '22046',
    '22037',
    '22033',
    '22032',
    '22031',
    '22030',
    '22027',
    '22026',
    '22025',
    '22024',
    '22021',
    '22020',
    '22015',
    '22013',
    '22011',
    '22010',
    '22007',
    '22006',
    '22003',
    '21921',
    '21915',
    '21901',
    '21842',
    '21813',
    '21811',
    '21804',
    '21801',
    '21798',
    '21797',
    '21794',
    '21793',
    '21791',
    '21790',
    '21788',
    '21787',
    '21784',
    '21783',
    '21780',
    '21778',
    '21777',
    '21776',
    '21775',
    '21774',
    '21773',
    '21771',
    '21770',
    '21769',
    '21762',
    '21759',
    '21758',
    '21757',
    '21756',
    '21755',
    '21754',
    '21742',
    '21740',
    '21738',
    '21737',
    '21727',
    '21723',
    '21717',
    '21716',
    '21714',
    '21713',
    '21710',
    '21705',
    '21704',
    '21703',
    '21702',
    '21701',
    '21666',
    '21660',
    '21651',
    '21640',
    '21638',
    '21629',
    '21619',
    '21617',
    '21613',
    '21601',
    '21532',
    '21502',
    '21410',
    '21409',
    '21405',
    '21403',
    '21402',
    '21401',
    '21286',
    '21244',
    '21239',
    '21237',
    '21236',
    '21234',
    '21231',
    '21230',
    '21229',
    '21228',
    '21227',
    '21226',
    '21225',
    '21224',
    '21223',
    '21222',
    '21221',
    '21220',
    '21218',
    '21217',
    '21216',
    '21215',
    '21214',
    '21213',
    '21212',
    '21211',
    '21210',
    '21209',
    '21208',
    '21207',
    '21206',
    '21205',
    '21204',
    '21202',
    '21201',
    '21163',
    '21160',
    '21158',
    '21157',
    '21155',
    '21153',
    '21152',
    '21146',
    '21144',
    '21140',
    '21136',
    '21133',
    '21128',
    '21123',
    '21122',
    '21120',
    '21117',
    '21114',
    '21113',
    '21108',
    '21106',
    '21104',
    '21094',
    '21093',
    '21090',
    '21087',
    '21085',
    '21078',
    '21076',
    '21075',
    '21074',
    '21072',
    '21071',
    '21070',
    '21069',
    '21065',
    '21062',
    '21061',
    '21060',
    '21055',
    '21054',
    '21053',
    '21052',
    '21050',
    '21049',
    '21048',
    '21047',
    '21046',
    '21045',
    '21044',
    '21043',
    '21042',
    '21040',
    '21037',
    '21036',
    '21035',
    '21032',
    '21031',
    '21030',
    '21029',
    '21024',
    '21021',
    '21015',
    '21014',
    '21013',
    '21012',
    '21009',
    '21001',
    '20919',
    '20915',
    '20912',
    '20910',
    '20906',
    '20905',
    '20904',
    '20903',
    '20902',
    '20901',
    '20896',
    '20895',
    '20894',
    '20892',
    '20891',
    '20886',
    '20885',
    '20883',
    '20882',
    '20880',
    '20879',
    '20878',
    '20877',
    '20876',
    '20875',
    '20874',
    '20872',
    '20871',
    '20868',
    '20866',
    '20862',
    '20861',
    '20860',
    '20859',
    '20857',
    '20855',
    '20854',
    '20853',
    '20852',
    '20851',
    '20850',
    '20849',
    '20842',
    '20841',
    '20840',
    '20839',
    '20838',
    '20837',
    '20836',
    '20833',
    '20832',
    '20830',
    '20827',
    '20825',
    '20818',
    '20817',
    '20816',
    '20815',
    '20814',
    '20812',
    '20805',
    '20803',
    '20794',
    '20790',
    '20788',
    '20787',
    '20785',
    '20784',
    '20783',
    '20782',
    '20781',
    '20779',
    '20778',
    '20777',
    '20776',
    '20775',
    '20774',
    '20773',
    '20772',
    '20770',
    '20769',
    '20765',
    '20764',
    '20763',
    '20762',
    '20761',
    '20759',
    '20758',
    '20757',
    '20755',
    '20754',
    '20751',
    '20750',
    '20749',
    '20748',
    '20747',
    '20746',
    '20745',
    '20744',
    '20743',
    '20742',
    '20741',
    '20740',
    '20737',
    '20736',
    '20735',
    '20733',
    '20732',
    '20731',
    '20726',
    '20725',
    '20724',
    '20723',
    '20722',
    '20721',
    '20720',
    '20719',
    '20718',
    '20717',
    '20716',
    '20715',
    '20714',
    '20712',
    '20711',
    '20710',
    '20709',
    '20708',
    '20707',
    '20706',
    '20705',
    '20704',
    '20703',
    '20701',
    '20695',
    '20693',
    '20690',
    '20689',
    '20685',
    '20678',
    '20677',
    '20676',
    '20675',
    '20664',
    '20662',
    '20659',
    '20658',
    '20657',
    '20656',
    '20653',
    '20650',
    '20646',
    '20645',
    '20642',
    '20640',
    '20639',
    '20637',
    '20634',
    '20632',
    '20627',
    '20625',
    '20623',
    '20622',
    '20621',
    '20620',
    '20619',
    '20618',
    '20617',
    '20616',
    '20613',
    '20612',
    '20611',
    '20608',
    '20607',
    '20604',
    '20603',
    '20602',
    '20601',
    '20588',
    '20418',
    '20263',
    '20198',
    '20197',
    '20196',
    '20195',
    '20194',
    '20193',
    '20192',
    '20191',
    '20190',
    '20188',
    '20187',
    '20186',
    '20185',
    '20184',
    '20182',
    '20181',
    '20180',
    '20178',
    '20177',
    '20176',
    '20175',
    '20174',
    '20172',
    '20171',
    '20170',
    '20169',
    '20168',
    '20167',
    '20166',
    '20165',
    '20164',
    '20163',
    '20160',
    '20159',
    '20158',
    '20156',
    '20155',
    '20154',
    '20153',
    '20152',
    '20151',
    '20148',
    '20147',
    '20146',
    '20144',
    '20143',
    '20142',
    '20141',
    '20140',
    '20139',
    '20138',
    '20137',
    '20136',
    '20135',
    '20134',
    '20133',
    '20132',
    '20131',
    '20130',
    '20129',
    '20128',
    '20126',
    '20124',
    '20123',
    '20122',
    '20121',
    '20120',
    '20119',
    '20118',
    '20117',
    '20116',
    '20115',
    '20114',
    '20113',
    '20112',
    '20111',
    '20110',
    '20109',
    '20108',
    '20107',
    '20106',
    '20105',
    '20103',
    '20102',
    '20101',
    '20079',
    '20072',
    '20037',
    '20036',
    '20032',
    '20024',
    '20020',
    '20019',
    '20018',
    '20017',
    '20016',
    '20015',
    '20012',
    '20011',
    '20010',
    '20009',
    '20008',
    '20007',
    '20005',
    '20003',
    '20002',
    '20001',
]);
